<template>
    <nav>
        
        <v-app-bar clipped-left  flat app  class="amber darken-3" 
        
        >
            <v-toolbar-items  >
            <v-app-bar-nav-icon class="white--text"  light @click="drawer = !drawer">
                <v-icon x-large>mdi-menu</v-icon>
            </v-app-bar-nav-icon>
            </v-toolbar-items>
            <v-toolbar-title class="red--text text-lg-h5 text-xl-h3" >
                
               
                <span class="red--text font-weight-bold"> M L I N P E K</span>
                
            </v-toolbar-title>
             <v-spacer> </v-spacer>

            
            <!-- Meni -->
            
            
                             
            <v-row class="hidden-sm-and-down "
                justify="space-around"
            >  
        
                <v-btn height="60"
                class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4
              "
                 text to ="/" >
                    Početna
                </v-btn>

                <v-btn height="60"
                    class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4
              "
                
                text to ="/onama" >
                    O nama
                </v-btn>
            <!--
                <v-btn height="60"
                    class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4
              "
                
                text to ="/istorijat" >
                    Istorijat
                </v-btn>
            -->
                <!-- drp meni -->
                <v-menu  open-on-hover offset-y transition="slide-x-transition"  :close-delay="1000" bottom>
                    <template v-slot:activator="{ on, attrs }" >
                    
                        <v-btn height="60"
                        
                        class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4
              "

                        text v-bind="attrs" v-on="on">
                            Proizvodnja &#9660;
                            
                        </v-btn>
                    </template>
                    <v-list class=" 
                amber darken-3 ">
                        <v-list-item  to="/proizvodnja/mlin">
                        <v-list-item-action>
                            <v-list-item-title
                                class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4
              "
                            >Mlin</v-list-item-title>
                         </v-list-item-action>
                         </v-list-item>

                        <v-list-item  to="/proizvodnja/pekara">
                        <v-list-item-action>
                            <v-list-item-title
                            class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4
              "
                            >Pekara</v-list-item-title>
                         </v-list-item-action>
                         </v-list-item>

                        <v-list-item  to="/proizvodnja/silos">
                        <v-list-item-action>
                            <v-list-item-title
                            class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4
              "
                            >Silos</v-list-item-title>
                         </v-list-item-action>
                         </v-list-item>

                         <v-list-item  to="/proizvodnja/susara">
                        <v-list-item-action>
                            <v-list-item-title
                            class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4
              "
                            >Sušara</v-list-item-title>
                         </v-list-item-action>
                         </v-list-item>

                         <v-list-item  to="/proizvodnja/ostale_djelatnosti">
                        <v-list-item-action>
                            <v-list-item-title
                            class="
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4
              "
                            >Ostale djelatnosti</v-list-item-title>
                         </v-list-item-action>
                         </v-list-item>
                        


                    </v-list>
                </v-menu>



                <v-btn height="60"
                class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4
              "
                
                 text to ="/distribucija" >
                    Distribucija
                </v-btn>
                <v-btn height="60"
                
                class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4
              "
                text to ="/kontakt" >
                    Kontakt
                
                </v-btn>
                <v-btn height="60"
                
                class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4
              "
                
                
                text to ="/obavjestenja" >
                    Obavještenja
                </v-btn>
                
            </v-row>    
            
            
            

          <!-- meni -->
        </v-app-bar>


        <v-navigation-drawer   width="340" :disable-resize-watcher="true"
        
        app v-model="drawer"  class="amber darken-3 my-14 " 
        
         >
            <v-list nav >
       <div v-for="(link, i) in navLinks" :key="i">

        <v-list-item
            v-if="!link.subLinks"
            :to="link.route"
            
           
            
        >
            <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title v-text="link.text" />
        </v-list-item>

        <v-list-group
            v-else
            :key="link.text"
            no-action
            
            :value="false"
        >
            <template v-slot:activator>
              
              <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
             </template>

            <v-list-item
                v-for="sublink in link.subLinks"
                :to="sublink.route"
                :key="sublink.text"
            >
                <v-list-item-icon>
                  <v-icon>{{ sublink.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title >{{ sublink.text }}</v-list-item-title>

            </v-list-item>

        </v-list-group>

    </div>

      </v-list>
         


        </v-navigation-drawer>
        
        

    </nav>
    
    

</template>
 

<script>
export default {
  data: () => ({
    drawer: false,
    
    
    navLinks: [
      { icon: 'home', text: 'Početna', route: '/'},
      { icon: 'group', text: 'O nama', route: '/onama'},
      //{ icon: 'group', text: 'Istorijat', route: '/istorijat'},
      {
          icon: 'settings', 
          text: 'Proizvodnja',
         // route: '/proizvodnja'
          
          subLinks: [
                        {text:'Mlin', route: '/proizvodnja/mlin'},
                        {text:'Pekara', route: '/proizvodnja/pekara'},
                        {text:'Silos', route: '/proizvodnja/silos'},
                        {text:'Sušara', route: '/proizvodnja/susara'},
                        {text:'Ostale djelatnosti', route: '/proizvodnja/ostale_djelatnosti'},
                    ]
      },
      
      
      { icon: 'directions_bus', text: 'Distribucija', route: '/distribucija'},
      { icon: 'contact_phone', text: 'Kontakt', route: '/kontakt'},
      { icon: 'photo_library', text: 'Obavještenja', route: '/obavjestenja'},
    ]
  }),
}
</script>