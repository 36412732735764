

<template>
  <v-app >
    
    
    <Navbar />
      
    <v-content class=" grey--text 
    text-sm-body-2 text-md-body-1 text-lg-h6 text-xl-h4 text-justify"
                      
    >
      <router-view></router-view>
     <div class="amber--text text-darken-3">
     <Footercomponent />
     </div >       
    </v-content>
    
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar' 
import Footercomponent from '@/components/Footercomponent'
  

export default {
 
  name: 'App',
 components: {Navbar,Footercomponent}, 
  data: () => ({
    //
  }),
};
</script>
