import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Početna',
    component: () => import( '../views/Pocetna.vue'),
    meta: {
      title: 'Mlinpek Početna stranica',
      metaTags: [
        {
          name: 'description',
          content: 'Mlinpek Prnjavor se bavi  proizvodnjom mlinarski i pekarski proizvoda, skladištenjem i sušenjem žitarica'
        },
        {
          property: 'keywords',
          content: 'Mlinpek, Prnjavor, mlinarski, pekarski, proizvodi, skladištenje žita, sušenje žita'
        }
      ]
    }
  },
  {
    path: '/onama',
    name: 'Onama',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Onama.vue')
  },
  
  {
    path: '/proizvodnja/mlin',
    name: 'Mlin', 
    component: () => import('../views/Mlin.vue'),
    meta: {
      title: 'Mlinpek mlin',
      metaTags: [
        {
          name: 'description',
          content: 'Proizvodi mlina su pšenična brašna T-400, T-500, T-850, integralno raževo brašno, pšenični griz i stočno brašno'
        },
        {
          property: 'keywords',
          content: 'Mlinpek, mlin, pšenično brašno, T-400, T-500, T-850, integralno raževo brašno, pšenični griz, stočno brašno'
        }
      ]
    }
  },
  {path: '/proizvodnja/pekara',
    name: 'Pekara', 
    component: () => import('../views/Pekara.vue'),
    meta: {
      title: 'Mlinpek pekara',
      metaTags: [
        {
          name: 'description',
          content: 'Pekara posjeduje opremu koja omogućava proizvodnju hljeba i raznih vrsta peciva i prezle.'
        },
        {
          property: 'keywords',
          content: 'Mlinpek, pekara, hljeb, peciva, prezle'
        }
      ]
    }
  },
  {
    path: '/proizvodnja/silos',
    name: 'Silos',
    component: () => import('../views/Silos.vue'),
    meta: {
      title: 'Mlinpek silos',
      metaTags: [
        {
          name: 'description',
          content: 'U silosu se vrši prijem i skladištenje žitarica, uglavnom pšenice i kukuruza. U cilju pravilnog skladištenja i očuvanja kvaliteta zrna vrši se redovno eleviranje i fumigacija.'
        },
        {
          property: 'keywords',
          content: 'Mlinpek, silos, prijem , skladištenje, žitarica, pšenica, kukuruz'
        }
      ]
    }
  }, 
  {path: '/proizvodnja/susara',
    name: 'Sušara', 
    component: () => import('../views/Susara.vue'),
    meta: {
      title: 'Mlinpek sušara',
      metaTags: [
        {
          name: 'description',
          content: 'Proces sušenja u sušari se sastoji od prijema žitarice u sušaru, sušenje, hlađenje zrna, transport u ćeliju za skladištenje'
        },
        {
          property: 'keywords',
          content: 'Mlinpek, sušara, prijem, sušenje, hlađenje, žitarica, kukuruz'
        }
      ]
    }
  },
  {path: '/proizvodnja/ostale_djelatnosti',
    name: 'Ostale_djelatnosti', 
    component: () => import('../views/Ostale_djelatnosti.vue')
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: () => import('../views/Kontakt.vue'),
    meta: {
      title: 'Mlinpek kontakt',
      metaTags: [
        {
          name: 'description',
          content: 'Mlinpek Magistralni put 20 Prnjavor, Republika Srpska, BIH, TEL./FAX: +387 51 645 503, e-mail: mlinpek@zona.ba'
        },
        {
          property: 'keywords',
          content: 'Mlinpek, Pranjavor, Republika Srpska, BIH, TEL./FAX: +387 51 645 503, e-mail: mlinpek@zona.ba'
        }
      ]
    }
  },
  {
      path: '/distribucija',
      name: 'Distribucija',
      component: () => import('../views/Distribucija.vue')
  },
  {
    path: '/obavjestenja',
    name: 'Obavjestenja',
    component: () => import('../views/Obavjestenja.vue')
  },
  {
    // catch all 404 - define at the very end
    path: "*",
    component: () => import("../views/NotFound.vue")
    }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/* pocetak */
// ...

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

// ...
/*  kraj */

export default router
